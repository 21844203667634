import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';

export const useIsPageEligibleForAdminAnnouncementBanner = () => {
	const { edition } = useSessionData();

	const isAABStandardFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.admin-announcement-banner.standard',
	);

	return edition === 'PREMIUM' || (isAABStandardFFEnabled && edition === 'STANDARD');
};
